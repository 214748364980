@charset "utf-8";

@import url('resources/assets/general/css/inter.css');

/* purgecss start ignore */
@tailwind base;

@tailwind components;
/* purgecss end ignore */

/* Fonts */

:root {
    --font-display: 'Inter', 'Lucida Sans Unicode', 'Lucida Grande', 'sans-serif';
    --font-body: 'Inter', 'Arial', 'Helvetica', 'sans-serif';
    --font-code: 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace';
    --font-icon: 'Font Awesome 5 Free';
    --font-icon-with-body: var(--font-icon), var(--font-body);
}

html {
    @apply font-body;
    word-break: break-word;
}

h1, h2, h3, h4, h5, h6, h7 {
    @apply font-display font-bold;
}

h1 {
    @apply font-extrabold;
}

pre:not(.moz-signature) {
    @apply font-code;
}

/* General */

* {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

a {
    @apply text-primary-800;
}

.sp-theme-dark a {
    @apply text-primary-100;
}

.sp-theme-dark a.sp-text-white {
    @apply text-white;
}

a:hover {
    @apply text-primary-900 cursor-pointer;
}

.sp-theme-dark a:hover {
    @apply text-primary-200;
}

@screen print {
    .sp-theme-dark a {
        @apply text-primary-800;
    }

    .sp-theme-dark a:hover {
        @apply text-primary-900;
    }
}

p {
    @apply my-6;
}

ol,
ul {
    @apply pl-6 list-decimal;
}

ul {
    @apply list-disc;
}

ul ul {
    @apply list-circle;
}

hr {
    @apply -mx-6 my-6 border-0 border-t border-tertiary;
}

blockquote {
    @apply pl-3 ml-2 border-l border-tertiary;
}

pre:not(.moz-signature) {
    @apply p-4 m-0 bg-secondary shadow rounded text-sm overflow-auto whitespace-pre;
}

@screen print {
    pre:not(.moz-signature) {
        @apply whitespace-normal;
    }
}

.sp-description {
    @apply text-secondary text-sm;
}

.sp-badge {
    @apply bg-primary-900 px-2 py-1 rounded-full text-white text-xs font-bold leading-none;
}

.sp-tag {
    @apply inline-block px-2 mr-1 bg-tertiary rounded-full text-xs uppercase;
}

.sp-avatar {
    @apply inline-block max-w-lg rounded-full;
}

.sp-collapsible {
    @apply flex items-center px-4 py-2 m-0 bg-secondary shadow rounded font-semibold cursor-pointer;
}

.sp-collapsible h4 {
    @apply flex-grow;
}

.sp-collapsible .sp-chevron {
    @apply flex-none sp-mt-1/2;
}

.sp-collapsible + .sp-hidden {
    @apply sp-mt-6;
}

.sp-ellipsis {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sp-ellipsis i {
    @apply opacity-0;
    animation: dot 1s infinite;
    animation-delay: 0.0s;
}

.sp-ellipsis i+i {
    @apply opacity-0;
    animation: dot 1s infinite;
    animation-delay: 0.2s;
}

.sp-ellipsis i+i+i {
    @apply opacity-0;
    animation: dot 1s infinite;
    animation-delay: 0.3s;
}

@-webkit-keyframes dot {
    0% { @apply opacity-0 }
    50% { @apply opacity-0 }
    100% { @apply opacity-100 }
}

/* Alerts */

.sp-alert {
    @apply px-5 py-3 mb-6 bg-gray-200 border-l-4 border-gray-500 rounded text-gray-800;
}

.sp-theme-dark .sp-alert {
    @apply border-0 bg-gray-700 text-gray-100 shadow;
}

.sp-alert.sp-alert-error {
    @apply bg-red-200 border-red-500 text-red-800;
}

.sp-theme-dark .sp-alert.sp-alert-error {
    @apply bg-red-700 text-red-100;
}

.sp-alert.sp-alert-info {
    @apply bg-blue-200 border-blue-500 text-blue-800;
}

.sp-theme-dark .sp-alert.sp-alert-info {
    @apply bg-blue-700 text-blue-100;
}

.sp-alert.sp-alert-success {
    @apply bg-green-200 border-green-500 text-green-800;
}

.sp-theme-dark .sp-alert.sp-alert-success {
    @apply bg-green-700 text-green-100;
}

.sp-alert.sp-alert-warning {
    @apply bg-orange-200 border-orange-500 text-orange-800;
}

.sp-theme-dark .sp-alert.sp-alert-warning {
    @apply bg-orange-700 text-orange-100;
}

.sp-alert.sp-alert-bottom {
    @apply mt-6 mb-0;
}

.sp-alert.sp-alert-m-0 {
    @apply m-0;
}

.sp-alert .sp-alert-close {
    @apply px-1 py-1/2 ml-3/2 -mr-3/2 bg-primary rounded float-right cursor-pointer;
}

.sp-alert .sp-alert-close:hover {
    @apply bg-tertiary;
}

.sp-alert .fa-times {
    @apply cursor-pointer;
}

.sp-alert.sp-alert-with-icon {
    @apply sp-flex;
}

.sp-alert.sp-alert-with-icon .sp-alert-content {
    @apply flex-grow;
}

.sp-alert.sp-alert-with-icon .sp-alert-icon {
    @apply flex-none py-3/2 mr-4 text-4xl;
}

.sp-theme-dark .sp-alert a,
.sp-theme-dark .sp-alert button.as-link,
.sp-theme-dark .sp-alert button.as-link:focus,
.sp-theme-dark .sp-alert button.as-link:active {
    @apply text-primary;
}

.sp-theme-dark .sp-alert a:hover,
.sp-theme-dark .sp-alert button.as-link:hover,
.sp-theme-dark .sp-alert button.as-link.active {
    @apply text-secondary;
}

.sp-alert input,
.sp-alert input[type="radio"],
.sp-alert input[type="radio"]:before,
.sp-alert input[type="checkbox"],
.sp-alert input[type="checkbox"]:before,
.sp-alert select {
    @apply bg-primary border-primary;
}

/* Filters (yadcf/own) */

div.sp-filter-results {
    @apply mb-6 border-collapse;
}

div.sp-filter-results h3 {
    @apply m-0 mb-3;
}

div.sp-filter-results > div.sp-table {
    @apply w-full table-fixed;
}

div.sp-filter-results > div.sp-table div.sp-table-row {
    @apply border-t-8 border-transparent;
}

div.sp-filter-results > div.sp-table div.sp-table-row:first-of-type {
    @apply border-t-0;
}

div.sp-filter-results > div.sp-table div.sp-table-row.sp-border-0 {
    @apply border-0;
}

div.sp-filter-results input, .sp-filter-results select {
    @apply px-3 py-3/2 rounded-r-none;
    max-width: calc(100% - 2.25rem);
}

div.sp-filter-results select {
    @apply pr-8;
}

div.sp-filter-results button {
    @apply pl-2 pr-2 border-0 rounded-l-none;
}

.yadcf-filter-wrapper {
    @apply m-0;
}

@screen sm {
    div.sp-filter-results {
        @apply border border-tertiary rounded;
    }

    div.sp-filter-results h3 {
        @apply px-3 py-2 mb-0 bg-secondary rounded-t text-secondary text-lg font-semibold leading-normal;
    }

    div.sp-filter-results > div.sp-table {
        @apply m-3 table-auto;
    }
}

@screen md {
    div.sp-filter-results label, .sp-filter-results div div div {
        @apply table-cell;
    }

    div.sp-filter-results label {
        @apply pr-3 text-right;
    }
}

/* Forms */

form {
    @apply m-0;
}

select[multiple='multiple'] {
    @apply h-auto;
}

input, select, textarea, button {
    @apply align-middle;
}

input, select, textarea {
    @apply appearance-none max-w-full py-3/2 px-3 bg-secondary text-primary border-2 border-secondary rounded leading-normal;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-sizing: border-box;
    min-height: 2.5rem;
}

input[type="search"] {
    -webkit-appearance: none;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
    @apply hidden h-0 w-0;
}
input[type="search"]::-ms-reveal {
    @apply hidden h-0 w-0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
    @apply hidden;
}

/* hides the calendar picker icon in Chrome */
input[type="date"]::-webkit-calendar-picker-indicator {
    @apply hidden;
    -webkit-appearance: none;
}

/* fixes height issue in Chrome mobile */
input[type="date"] {
    @apply py-1;
}

select:not([multiple]) {
    @apply pr-8 bg-no-repeat;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
    background-position: right 0.125rem center;
    background-size: 1.5rem;
}

select option {
    @apply bg-secondary text-primary;
}

input::placeholder,
textarea::placeholder {
    @apply text-secondary;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    @apply text-secondary;
}

textarea {
    @apply w-full resize-y;
}

input:focus, select:focus, textarea:focus {
    @apply bg-primary outline-none;
}

input[type="checkbox"],
input[type="radio"],
input[type="file"] {
    @apply p-0 border-0;
    background: none;
    min-height: auto;
}

input[type="checkbox"] {
    @apply rounded-none;
    -moz-appearance: checkbox;
}

input[type="radio"] {
    -moz-appearance: radio;
}

input[type="checkbox"],
input[type="radio"] {
    @apply relative inline-block h-5 w-5 mb-1/2 mr-3/2 bg-tertiary outline-none align-middle cursor-pointer;
}

/* Fix for Firefox which doesn't support custom radio buttons/checkboxes */
@supports (-moz-appearance: none) {
    input[type="checkbox"],
    input[type="radio"] {
        @apply h-auto w-auto;
    }
}

input[type="checkbox"].sp-m-0,
input[type="radio"].sp-m-0 {
    @apply m-0;
}

input[type="checkbox"]::before,
input[type="radio"]:before {
    @apply block p-2 border-2 border-tertiary;
    content: "";
}

input[type="radio"]:before {
    @apply p-3/2 border-4;
}

input[type="radio"],
input[type="radio"]:before {
    @apply rounded-full;
}

input[type="checkbox"]:hover::before,
input[type="radio"]:hover::before {
    @apply bg-tertiary;
}

input[type="checkbox"]:checked::before,
input[type="checkbox"]:checked:hover::before {
    @apply h-5 w-5 px-1/2 py-0 font-icon font-bold text-green-600 text-xs leading-snug;
    content: '\f00c';
}

input[type="radio"]:checked::before,
input[type="radio"]:checked:hover::before {
    @apply bg-green-600;
}

input[type="file"] {
    @apply w-full rounded-none;
}

button,
input[type="button"],
input[type="submit"],
.sp-button {
    @apply py-2 px-4 bg-secondary border-0 rounded shadow-inner text-primary text-center font-bold leading-normal;
}

.sp-button {
    @apply inline-block align-middle;
}

.sp-theme-dark a.sp-button {
    @apply text-primary;
}

button:not([disabled]):hover,
input[type="button"]:not([disabled]):hover,
input[type="submit"]:not([disabled]):hover,
.sp-button:hover {
    @apply bg-tertiary text-primary cursor-pointer;
}

button.sp-button-sm,
input[type="button"].sp-button-sm,
input[type="submit"].sp-button-sm,
.sp-button-sm {
    @apply py-1 px-3;
}

input[type="submit"],
input[type="button"].sp-button-submit,
.sp-button-submit {
    @apply bg-primary-800 text-white;
}

input[type="submit"]:not([disabled]):hover,
input[type="button"].sp-button-submit:not([disabled]):hover,
.sp-button-submit:not([disabled]):hover {
    @apply bg-primary-900 text-white;
}

button.sp-button-red,
input[type="button"].sp-button-red,
.sp-button-red {
    @apply bg-red-700 text-white;
}

button.sp-button-red:hover,
input[type="button"].sp-button-red:hover,
.sp-button-red:hover {
    @apply bg-red-800 text-white;
}

button.sp-button-orange,
input[type="button"].sp-button-orange,
.sp-button-orange {
    @apply bg-orange-700 text-white;
}

button.sp-button-orange:hover,
input[type="button"].sp-button-orange:hover,
.sp-button-orange:hover {
    @apply bg-orange-800 text-white;
}

.sp-button-group {
    @apply flex;
}

.sp-button-group button,
.sp-button-group .sp-button {
    @apply rounded-none;
}

.sp-button-group *:first-child {
    @apply rounded-l;
}

.sp-button-group *:last-child {
    @apply rounded-r;
}

button:disabled,
input:disabled,
textarea:disabled,
select:disabled {
    @apply opacity-50;
}

input[readonly="readonly"],
textarea[readonly="readonly"] {
    @apply opacity-75;
}

button:disabled:focus,
input:disabled:focus,
textarea:disabled:focus,
input[readonly="readonly"]:focus,
textarea[readonly="readonly"]:focus {
    @apply bg-tertiary;
}

input[type="submit"]:disabled:focus,
input[type="button"].sp-button-submit:disabled:focus,
.sp-button-submit:disabled:focus {
    @apply bg-primary-800;
}

input.sp-toggle {
    @apply absolute invisible;
}

input.sp-toggle + label {
    @apply relative px-8 py-2 rounded-full cursor-pointer transition-all duration-500;
}

input.sp-toggle + label:before,
input.sp-toggle + label:after {
    @apply block absolute;
    content: "";
}

input.sp-toggle + label:before {
    @apply bottom-0 left-0 right-0 top-0 bg-primary border-2 border-tertiary rounded-full;
}

input.sp-toggle + label:after {
    @apply w-6 inset-0 right-auto m-3/2 mr-8 bg-tertiary rounded-full transition-all duration-500;
}

input.sp-toggle:checked + label:before {
    @apply border-green-500;
}

input.sp-toggle:checked + label:after {
    @apply left-auto right-0 ml-8 mr-3/2 bg-green-500;
}

button.as-link,
button.as-link:focus,
button.as-link:active {
    @apply text-primary-800;
    /* Remove all decorations to look like normal text */
    background: 0;
    border: 0;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: inline;
    font: inherit;
    height: auto;
    margin: 0;
    padding: 0;
    outline: 0;
    outline-offset: 0;
    /* Additional styles to look like a link */
    cursor: pointer;
}

.sp-theme-dark button.as-link,
.sp-theme-dark button.as-link:focus,
.sp-theme-dark button.as-link:active {
    @apply text-secondary;
}

/* Remove extra space inside buttons in Firefox */
button.as-link::-moz-focus-inner {
    @apply p-0 border-0;
}

button.as-link:hover,
button.as-link.active {
    @apply bg-transparent text-primary-900;
}

.sp-theme-dark button.as-link:hover,
.sp-theme-dark button.as-link.active {
    @apply text-primary;
}

.sp-form-row {
    @apply mb-6;
}

.sp-form-row > label {
    @apply block mb-2 font-semibold;
}

.sp-form-row > label:empty {
    @apply mb-0;
}

.sp-form-row .sp-input-container > div.sp-description {
    @apply mt-2;
}

@screen md {
    .sp-form-row {
        @apply flex items-start;
    }

    .sp-form-row > label {
        @apply flex-none w-48 py-2 pr-4 mb-0 text-right;
    }

    .sp-form-row .sp-input-container {
        @apply flex-initial self-center w-full min-w-0;
    }
}

@screen print {
    .sp-form-row {
        @apply flex items-start;
    }

    .sp-form-row > label {
        @apply flex-none w-48 py-2 pr-4 mb-0 text-right;
    }

    .sp-form-row .sp-input-container {
        @apply flex-initial self-center w-full min-w-0;
    }
}

.sp-form-button {
    @apply mt-6;
}

.sp-form-button input[type="button"],
.sp-form-button input[type="submit"],
.sp-form-button button {
    @apply mr-3;
}

.sp-form-button.sp-fixed-bottom {
    @apply sticky bottom-0 p-6 -mb-6 -mx-6 bg-primary border-t border-tertiary z-50;
}

.sp-form-full .sp-input-container,
.sp-form-full input[type="email"],
.sp-form-full input[type="number"],
.sp-form-full input[type="password"],
.sp-form-full input[type="search"],
.sp-form-full input[type="tel"],
.sp-form-full input[type="text"],
.sp-form-full input[type="url"],
.sp-form-full select,
.sp-form-full textarea {
    @apply w-full;
}

.sp-form-container {
    @apply px-6 py-3 -mx-6 border-t border-b border-tertiary;
}

.sp-form-container-t-0,
.sp-form-container:not(.sp-hidden) + .sp-form-container {
    @apply border-t-0;
}

.sp-form-container-t-0 {
    @apply pt-0;
}

.sp-form-container-b-0 {
    @apply pb-0 border-b-0;
}

.sp-form-container + .sp-form-button.sp-fixed-bottom {
    margin-top: -1px;
}

.sp-form-container.is-sortable {
    @apply relative overflow-x-auto;
}

.sp-form-container .sp-form-row {
    @apply m-0 py-3;
}

.sp-form-container.sp-collapsed {
    @apply px-6 bg-secondary cursor-pointer;
}

.sp-form-container.sp-collapsed div.sp-description {
    @apply pr-4 m-0;
}

@screen md {
    .sp-form-container.sp-collapsed {
        @apply px-0;
    }

    .sp-form-container.sp-collapsed .sp-form-row {
        @apply items-center;
    }

    .sp-form-container.sp-collapsed .sp-form-row > label {
        @apply ml-6;
    }
}

/* Tables */

table.sp-table-grid {
    @apply w-full border-0;
}

table.sp-table-grid thead th {
    @apply px-3 py-2 border-b-2 border-tertiary text-lg text-left;
}

table.sp-table-grid td {
    @apply px-3 py-2 border-b border-tertiary;
}

table.sp-table-grid thead th:first-child,
table.sp-table-grid td:first-child {
    @apply pl-6;
}

table.sp-table-grid thead th:last-child,
table.sp-table-grid td:last-child {
    @apply pr-6;
}

table.sp-table-grid input[type="checkbox"] {
    @apply m-0;
}

table.sp-organisation-users td.sp-name,
table.sp-organisation-users td.sp-access-level,
table.sp-organisation-users td.sp-remove {
    @apply block pl-6;
}

table.sp-organisation-users td.sp-name {
    @apply pt-4 pr-6 border-b-0;
}

table.sp-organisation-users td.sp-access-level {
    @apply border-b-0;
}

table.sp-organisation-users td.sp-access-level.sp-no-remove {
    @apply border-b pb-4;
}

table.sp-organisation-users td.sp-remove {
    @apply pb-4;
}

@screen md {
    table.sp-organisation-users td.sp-name,
    table.sp-organisation-users td.sp-access-level,
    table.sp-organisation-users td.sp-remove {
        @apply table-cell;
    }

    table.sp-organisation-users td.sp-name {
        @apply pl-6 pt-2 pr-3 border-b;
    }

    table.sp-organisation-users td.sp-access-level,
    table.sp-organisation-users td.sp-access-level.sp-no-remove {
        @apply pl-3 pb-2 border-b;
    }

    table.sp-organisation-users td.sp-remove {
        @apply pl-3 pb-2;
    }
}

/* Tabs */

ul.sp-tabs {
    @apply flex pl-0 mb-6 -mx-6 border-b border-tertiary list-none overflow-x-auto overflow-y-hidden whitespace-no-wrap;
}

ul.sp-tabs.sp-tabs-bottom {
    @apply mt-6 mb-0;
}

ul.sp-tabs li:first-child {
    @apply pl-6;
}

ul.sp-tabs li:last-child {
    @apply pr-6;
}

ul.sp-tabs li a {
    @apply block py-2 px-4 bg-primary text-tertiary;
}

ul.sp-tabs li.sp-active a {
    @apply bg-secondary rounded-t text-primary;
}

ul.sp-tabs li:not(.sp-active):hover a {
    @apply text-primary cursor-pointer;
}

ul.sp-tabs li .sp-badge {
    @apply ml-1 bg-tertiary text-secondary;
}

ul.sp-tabs li.sp-active .sp-badge {
    @apply bg-primary;
}

.sp-tabs-content {
    @apply p-6 -mt-6 border border-t-0 border-tertiary rounded rounded-t-none;
}

@screen print {
    ul.sp-tabs li a {
        @apply bg-transparent;
    }
}

/* Dropdowns */

.sp-dropdown-container {
    @apply inline-block;
}

.sp-dropdown-container .sp-dropdown {
    @apply hidden absolute z-100;
}

.sp-dropdown-container .sp-dropdown > ul,
.sp-dropdown-container .sp-dropdown > div {
    @apply w-48 p-0 mt-1/2 list-none bg-primary border border-tertiary rounded shadow text-sm overflow-hidden overflow-y-auto;
    max-height: 15rem;
}

.sp-dropdown-container .sp-dropdown > div {
    @apply p-2 overflow-visible;
}

.sp-dropdown-container .sp-dropdown ul li {
    @apply block;
}

.sp-dropdown-container .sp-dropdown ul li.sp-hidden {
    @apply hidden;
}

.sp-dropdown-container .sp-dropdown ul li a,
.sp-dropdown-container .sp-dropdown ul li.sp-dropdown-item {
    @apply block px-3 py-3/2;
}

.sp-dropdown-container .sp-dropdown ul li a:hover {
    @apply text-primary;
}

.sp-dropdown-container .sp-dropdown ul li a:hover,
.sp-dropdown-container .sp-dropdown ul li.sp-selected a,
.sp-dropdown-container .sp-dropdown ul li.sp-selected a:hover {
    @apply bg-secondary;
}

.sp-dropdown-container .sp-dropdown ul li.sp-selected a,
.sp-dropdown-container .sp-dropdown ul li.sp-selected a:hover {
    @apply font-bold;
}

.sp-dropdown-container .sp-dropdown select {
    @apply w-full;
}

@screen md {
    .sp-dropdown-container .sp-dropdown > ul,
    .sp-dropdown-container .sp-dropdown > div {
        @apply w-56;
    }

    .sp-dropdown-container .sp-dropdown ul li.md\:sp-hidden {
        @apply hidden;
    }
}

@screen lg {
    .sp-dropdown-container .sp-dropdown ul li.lg\:sp-hidden {
        @apply hidden;
    }
}

/* List styles */

ul.sp-item-list {
    @apply inline-block p-0 list-none;
}

ul.sp-item-list > li {
    @apply inline-block;
}

ul.sp-item-list > li:after {
    content: ", ";
}

ul.sp-item-list > li:last-child:after {
    content: "";
}

ul.sp-article-list {
    @apply p-0 list-none;
}

ul.sp-article-list > li {
    @apply mt-6;
}

ul.sp-article-list > li:first-child {
    @apply mt-0;
}

ul.sp-article-list > li a h4 {
    @apply my-0 text-lg;
}

ul.sp-article-list > li p {
    @apply my-2 truncate;
}

ul.sp-article-details {
    @apply p-0;
}

ul.sp-article-details > li {
    @apply inline-block mr-4;
}

ul.sp-article-details > li .fas {
    @apply text-xs;
}

ul.sp-article-details > li:last-child {
    @apply mr-0;
}

ul.sp-category-article-list {
    @apply pl-5 list-none;
}

ul.sp-category-article-list > li {
    @apply mb-1 truncate;
}

ul.sp-category-article-list > li:before {
    @apply mr-2 font-icon font-bold text-secondary text-xs;
    content: '\f15b';
}

ul.sp-article-comments,
ul.sp-article-comments ul {
    @apply p-0 list-none;
}

ul.sp-article-comments > li {
    @apply pt-6 mt-6 border-t border-secondary;
}

ul.sp-article-comments > li li {
    @apply p-0 mt-6 border-t-0;
}

ul.sp-article-comments img.sp-avatar {
    @apply max-w-md mt-1 mr-3 float-left;
}

ul.sp-article-comments div.sp-article-comment {
    @apply ml-16;
}

ul.sp-article-comments ul li img.sp-avatar {
    @apply max-w-sm;
}

ul.sp-article-comments ul li div.sp-article-comment {
    @apply ml-12;
}

ul.sp-article-comments .sp-comment-score {
    @apply ml-3/2;
}

/* Articles */

.sp-article h1,
.sp-article h2,
.sp-article h3,
.sp-article h4,
.sp-article h5,
.sp-article h6,
.sp-article h7 {
    @apply my-6;
}

/* Messages */

.sp-message {
    @apply p-4 mt-3 border border-secondary rounded;
}

.sp-message:first-child {
    @apply mt-0;
}

.sp-message.sp-new-message {
    @apply bg-blue-100 border-blue-200;
}

.sp-theme-dark .sp-message.sp-new-message {
    @apply bg-blue-900 border-0;
}

.sp-message .sp-message-text,
.sp-message .sp-message-text-edit,
.sp-message ul.sp-attachments {
    @apply mt-4;
}

.sp-message .sp-message-text * {
    @apply h-auto !important;
}

.sp-message .sp-message-text .hide {
    @apply block;
}

.sp-message .sp-message-text img {
    @apply max-w-full;
}

.sp-message .sp-message-text hr {
    @apply mx-0;
}

.sp-message .sp-message-text table {
    @apply float-none;
}

.sp-theme-dark .sp-message .sp-message-text * {
    color: inherit !important;
    background-color: inherit !important;
}

.sp-theme-dark .sp-message .sp-message-text a {
    @apply text-primary-100 !important;
}

.sp-theme-dark .sp-message .sp-message-text a:hover {
    @apply text-primary-200 !important;
}

.sp-theme-dark .sp-message .sp-message-text pre {
    @apply bg-secondary !important;
}

.sp-message ul.sp-attachments > li {
    @apply max-w-5xl;
}

@screen md {
    .sp-message {
        @apply p-6;
    }

    .sp-message .sp-message-text,
    .sp-message .sp-message-text-edit,
    .sp-message .sp-attachments {
        @apply ml-16;
    }
}

/* Attachments */

.sp-file-upload {
    @apply absolute w-full h-full top-0 left-0 opacity-0 overflow-hidden;
}

.sp-file-input {
    @apply inline-block relative;
}

ul.sp-attached-files {
    @apply p-0 list-none;
}

ul.sp-attached-files li {
    @apply relative pl-3 pr-10 py-1 bg-secondary border border-b-0 border-tertiary;
}

ul.sp-attached-files li.sp-hidden + li {
    @apply mt-4 rounded-t;
}

ul.sp-attached-files li:not(.sp-hidden):first-child {
    @apply rounded-t;
}

ul.sp-attached-files li:not(.sp-hidden):last-child {
    @apply border-b border-tertiary rounded-b;
}

ul.sp-attached-files .sp-progress-bar {
    @apply absolute w-full bottom-0 left-0;
}

ul.sp-attached-files .sp-progress-bar .sp-bar {
    @apply block h-1/2 w-0 bg-green-600;
}

ul.sp-attached-files .sp-delete-attachment {
    @apply px-3 py-2 ml-auto -mr-10 -my-1 leading-none cursor-pointer;
}

ul.sp-attached-files .sp-delete-attachment:hover {
    @apply bg-red-800;
}

ul.sp-attached-files li.sp-hidden + li .sp-delete-attachment,
ul.sp-attached-files li:not(.sp-hidden):first-child .sp-delete-attachment {
    @apply rounded-tr;
}

ul.sp-attached-files li:not(.sp-hidden):last-child .sp-delete-attachment {
    @apply rounded-br;
}

.sp-attachment-dragover {
    @apply px-4 py-3 my-4 bg-secondary border border-4 border-tertiary border-dashed rounded text-secondary;
}

ul.sp-attachments {
    @apply p-0 list-none;
}

ul.sp-attachments > li {
    @apply inline-block w-full mt-3 mr-3;
}

ul.sp-attachments > li a {
    @apply block px-4 py-2 bg-secondary rounded;
}

ul.sp-attachments > li a:hover {
    @apply bg-tertiary;
}

ul.sp-attachments > li a div {
    @apply truncate;
}

@screen sm {
    ul.sp-attachments > li {
        @apply w-64;
    }
}

/* Custom Fields */

.sp-form-customfields .redactor-box {
    @apply border-0;
}

.sp-form-customfields .redactor-in,
.sp-form-customfields .redactor-read-only {
    @apply py-3/2 px-3 bg-secondary border-2 border-secondary rounded;
}

.sp-form-customfields .redactor-focus .redactor-in {
    @apply bg-primary;
}

/* Email preview */

.sp-email-content img,
.merge-field_preview img {
    @apply inline;
}

.sp-email-content table,
.sp-email-content table th,
.sp-email-content table td,
.merge-field_preview table,
.merge-field_preview table th,
.merge-field_preview table td {
    @apply box-content;
}

@tailwind utilities;