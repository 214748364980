@charset "utf-8";

body {
    @apply bg-secondary my-6 text-primary;
}

@screen sm {
    body {
        @apply m-6;
    }
}

@screen md {
    body {
        @apply m-12;
    }
}

.sp-logo {
    @apply inline w-16 text-primary-800;
}

@screen md {
    .sp-logo {
        @apply w-24;
    }
}

.sp-theme-dark .sp-logo {
    @apply text-secondary;
}

.sp-logo path {
    @apply fill-current;
}

.sp-logo.sp-logo-big {
    @apply absolute w-64 left-50 top-50 -ml-32 -mt-32;
}

.sp-container {
    max-width: 768px;
}

.sp-content {
    @apply p-6 bg-primary shadow;
}

.sp-theme-dark .sp-content {
    @apply shadow-md;
}

@screen sm {
    .sp-content {
        @apply rounded;
    }
}

.loadinggif {
    @apply relative;
}

.loadinggif:after {
    @apply absolute -mt-12 -ml-8 font-icon font-bold text-primary-600 text-6xl;
    animation: fa-spin 2s infinite linear;
    content: "\f013";
    top: 50%;
    left: 50%;
}

.sp-theme-dark .loadinggif:after {
    @apply text-primary-200;
}

/* Headings */

h1 {
    @apply mb-6 leading-none text-3xl;
}

h2 {
    @apply my-6 text-xl;
}

h3 {
    @apply mt-4 mb-1 text-base;
}

/* Progress Bar */

.sp-progress, .sp-progress-bg {
    @apply h-6 shadow;
}

.sp-progress {
    @apply bg-gray-300 shadow;
}

.sp-theme-dark .sp-progress {
    @apply bg-primary shadow-md;
}

.sp-progress-bg {
    @apply overflow-hidden;

    animation: move 1.5s linear infinite;
    background-image: linear-gradient(-45deg, #68d391 33%, #48bb78 33%, #48bb78 66%, #68d391 66%);
    background-size: 3rem 1.5rem;
}

.sp-theme-dark .sp-progress-bg {
    background-image: linear-gradient(-45deg, #38a169 33%, #48bb78 33%, #48bb78 66%, #38a169 66%);
}

@screen sm {
    .sp-progress, .sp-progress-bg {
        @apply rounded;
    }

    .sp-progress {
        @apply mb-6;
    }
}

/* Animate the stripes */

@-webkit-keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 48px 0;
    }
}
@-moz-keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 48px 0;
    }
}
@-ms-keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 48px 0;
    }
}
@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 48px 0;
    }
}

/* Tables */

table {
    @apply mt-6 border border-tertiary border-b-0 text-sm;
}

table td, table th {
    @apply block p-2 border-b border-tertiary;
}

table th {
    @apply w-full bg-secondary font-bold text-left;
}

@screen sm {
    table td, table th {
        @apply table-cell;
    }

    table th {
        @apply w-64 text-right;
    }
}

/* Requirements */

.sp-requirement {
    @apply w-full mb-6;
}

.sp-requirement:last-of-type {
    @apply mb-0;
}

.sp-requirement .sp-requirement-status {
    @apply w-12;
}

.sp-requirement .sp-requirement-status .fas {
    @apply text-4xl;
}

.sp-requirement .sp-is-valid {
    @apply text-secondary;
}

.sp-requirement .sp-requirement-details {
    @apply pl-2;
}

.sp-requirement .sp-requirement-details h2 {
    @apply mt-0 mb-1;
}

.sp-requirement h3 {
    @apply text-secondary;
}

.sp-requirement h3 + table {
    @apply mt-0;
}
